<template>
  <div class="wrap">
    <div @mouseover="mouseOver"
         @mouseleave="mouseLeave">
      <swiper :options="swiperOption"
              class="swiper-banner"
              ref="swiperBanner">
        <!-- slides -->
        <swiper-slide>
          <div class="img-bg banner1"
               :style="{backgroundImage: 'url(' + imgUrl.banner1 + ')'}"
               swiper-animate-effect="fadeIn"
               swiper-animate-duration="0.3s"
               swiper-animate-delay="0.3s"></div>
          <img class="img-txt ani"
               src="../../assets/images/index-banner1-txt.png"
               alt=""
               swiper-animate-effect="zoomIn"
               swiper-animate-duration="0.5s"
               swiper-animate-delay="0.3s"
               style="margin-top: -17px;">
          <!-- <p class="txt ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="0.5s" swiper-animate-delay="0.3s" style=" width: 500px; height: 100%; position: relative; top: 40%; left: 30%; font-size: 40px; color: #fff;">CDR+数字病区</p> -->
        </swiper-slide>
        <swiper-slide>
          <div class="img-bg banner2"
               :style="{backgroundImage: 'url(' + imgUrl.banner2 + ')'}"
               swiper-animate-effect="fadeIn"
               swiper-animate-duration="0.3s"
               swiper-animate-delay="0.3s"></div>
          <img class="img-txt ani"
               src="../../assets/images/index-banner2-txt.png"
               alt=""
               swiper-animate-effect="zoomInRight"
               swiper-animate-duration="0.5s"
               swiper-animate-delay="0.3s"
               style="margin-top: -17px;">
          <!-- <p class="img-txt ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="0.5s" swiper-animate-delay="0.3s" style=" width: 500px; height: 100%; position: relative; top: 40%; left: 30%; font-size: 40px; color: #fff;">未来人类计划</p> -->
        </swiper-slide>
        <swiper-slide>
          <div class="img-bg banner3"
               :style="{backgroundImage: 'url(' + imgUrl.banner3 + ')'}"
               swiper-animate-effect="fadeIn"
               swiper-animate-duration="0.3s"
               swiper-animate-delay="0.3s"></div>
          <img class="img-txt ani"
               src="../../assets/images/index-banner3-txt.png"
               alt=""
               swiper-animate-effect="zoomInDown"
               swiper-animate-duration="0.5s"
               swiper-animate-delay="0.3s"
               style="margin-top: -50px;">
          <!-- <p class="img-txt ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="0.5s" swiper-animate-delay="0.3s" style=" width: 500px; height: 100%; position: relative; top: 40%; left: 30%; font-size: 40px; color: #fff;">英特沃德 健康新方向</p> -->
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination"
             slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white"
             slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white"
             slot="button-next"></div>
      </swiper>
    </div>
    <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>数字病区<em class="line">|</em><em class="color">CDR</em> 平台</h2>
        <div class="details">
          <div class="img">
            <div class="img1"></div>
            <div class="img2"><img :src="imgUrl.cdr"
                   alt=""></div>
          </div>
          <div class="text">
            <p>CDR平台是帮助建立以患者为中心的服务理念，为医院建设未来病区所需CDR基层数据采集与应用的软件+硬件、开放的、兼容性的高速平台。</p>
            <p>通过CDR+集成的一系列医疗器械与设备，实现在普通病床床旁的医疗服务应用，实现医院医疗与护理数字化、自动化，系统还可为建设医联体和高端医养联合体座位基础平台。通过CDR+集成的一系列医疗器械与设备，实现在普通病床床旁的医疗服务应用。</p>
            <p>采集、处理、传输、显示数据和数据应用是医院CDR临床大数据的重要组成部分。打造病区数字化、智能化、自动化及护理服务平台。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>数字病区<em class="line">|</em><em class="color">IOT</em> 平台</h2>
        <div class="details">
          <div class="text border bg">
            <p>物联网英文名称为The Internet of things，IoT便是物联网的英文缩写，物联网是基于互联网、广播电视网、传统电信网等信息承载体，让所有能够被独立寻址的普通物理对象实现互联互通的网络。它具有普通对象设备化、自治终端互联化和普适服务智能化3个重要特征。</p>
            <p>物联网是通过射频识别（RFID）、红外感应器、全球定位系统、激光扫描器等信息传感设备，按约定的协议，把任何物品与互联网连接起来，进行信息交换和通讯，以实现智能化识别、定位、跟踪、监控和管理的一种网络，物联网就是“物物相连的互联网”。</p>
            <p>用一句话概括就是，把所有物品通过信息传感设备与互联网连接起来，进行信息交换，即物物相息，以实现智能化识别和管理。</p>
          </div>
          <div class="img only-img">
            <img :src="imgUrl.iot"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="en t3"></div>
      <div class="inner product">
        <h2>数字病区<em class="line">|</em>医疗<em class="color">设备</em></h2>
        <div class=""
             id="certify">
          <swiper :options="swiperOption2"
                  ref="mySwiper">
            <!-- slides -->
            <swiper-slide><img :src="imgUrl.equipment1" />
              <p>CDR数字病区</p>
            </swiper-slide>
            <swiper-slide><img :src="imgUrl.equipment2" />
              <p>病区24H大屏监测系统</p>
            </swiper-slide>
            <!-- <swiper-slide><img :src="imgUrl.equipment3" />
              <p>病区床旁通用医护患终端</p>
            </swiper-slide> -->
            <swiper-slide><img :src="imgUrl.equipment4" />
              <p>病区床旁自动输液泵</p>
            </swiper-slide>
            <swiper-slide><img :src="imgUrl.equipment5" />
              <p>无扰式24H生命体征监护</p>
            </swiper-slide>
            <!-- Optional controls -->
            <!-- <div class="swiper-pagination"  slot="pagination"></div> -->
            <div class="swiper-button-prev swiper-button-white"
                 slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white"
                 slot="button-next"></div>
          </swiper>
          <!-- <img src="../../assets/images/banner33.png" alt=""> -->
        </div>
      </div>
    </div>
    <div class="content">
      <div class="en t4"></div>
      <div class="inner product">
        <h2>合作<em>伙伴</em></h2>
        <div class="img-list">
          <ul>
            <li><img src="../../assets/images/hz1.png" /></li>
            <li><img src="../../assets/images/hz2.png" /></li>
            <li><img src="../../assets/images/hz3.png" /></li>
            <li><img src="../../assets/images/hz4.png" /></li>
            <li><img src="../../assets/images/hz5.png" /></li>
            <li><img src="../../assets/images/hz6.png" /></li>
            <li><img src="../../assets/images/hz7.png" /></li>
            <li><img src="../../assets/images/hz8.png" /></li>
            <li><img src="../../assets/images/hz9.png" /></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiperAnimateCache, swiperAnimate } from '../../assets/js/swiper.animate.min.js'
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'Home',
  metaInfo: {
    title: '英特沃德（武汉）医疗科技发展有限公司', // set a title
    meta: [{ // set meta
      name: '医疗系统开发，医疗大数据应用，软件系统研发与集成，医疗装备研发生产与集成，数字病区',
      content: '英特沃德（武汉）医疗高科，是集医疗系统开发、医疗大数据应用、软件系统研发与集成、医疗装备研发生产与集成、投资、销售与运营服务为一体的高科技企业，项目坐落在中国光谷。'
    }],
    link: [{ // set link
      rel: '英特沃德（武汉）医疗科技发展有限公司',
      href: 'http://www.ytwdwh.com/'
    }]
  },
  created () {
    this.imgUrl.banner1 = getAliyunImg.signatureUrl('ytwd/gw/index-banner11.png')
    this.imgUrl.banner2 = getAliyunImg.signatureUrl('ytwd/gw/index-banner22.png')
    this.imgUrl.banner3 = getAliyunImg.signatureUrl('ytwd/gw/index-banner33.png')
    this.imgUrl.cdr = getAliyunImg.signatureUrl('ytwd/gw/cdr2.png')
    this.imgUrl.iot = getAliyunImg.signatureUrl('ytwd/gw/iot.png')
    this.imgUrl.equipment1 = getAliyunImg.signatureUrl('ytwd/gw/equipment1.jpg')
    this.imgUrl.equipment2 = getAliyunImg.signatureUrl('ytwd/gw/equipment2.jpg')
    this.imgUrl.equipment3 = getAliyunImg.signatureUrl('ytwd/gw/equipment3.jpg')
    this.imgUrl.equipment4 = getAliyunImg.signatureUrl('ytwd/gw/equipment4.jpg')
    this.imgUrl.equipment5 = getAliyunImg.signatureUrl('ytwd/gw/equipment5.jpg')
  },
  data () {
    return {
      imgUrl: {
        banner1: '',
        banner2: '',
        banner3: '',
        cdr: '',
        iot: '',
        equipment1: '',
        equipment2: '',
        equipment3: '',
        equipment4: '',
        equipment5: ''
      },
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        on: {
          init: function () {
            // console.log(this)
            // console.log(swiperAnimateCache)
            swiperAnimateCache(this)
            swiperAnimate(this)
          },
          slideChangeTransitionStart: function () {
            swiperAnimate(this)
          },
          slideChangeTransitionEnd: function () {
            swiperAnimate(this)
          },
          slideChange: function () {
            swiperAnimate(this)
          }
        },
        loop: true
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
      },
      swiperOption2: {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        loopedSlides: 7,
        // autoplay: 2000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true // 允许点击小圆点跳转
        // },
        on: {
          progress: function (progress) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              var slideProgress = this.slides[i].progress
              var modify = 1
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
              }
              var translate = slideProgress * modify * 260 + 'px'
              var scale = 1 - Math.abs(slideProgress) / 5
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
              slide.transform('translateX(' + translate + ') scale(' + scale + ')')
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1)
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0)
              }
            }
          },
          setTransition: function (transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition)
            }
          },
          click: function () {
            if (this.clickedIndex === undefined) {
              return false
            } else {
              this.slideTo(this.clickedIndex, 300, false)
            }
          }
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    swiperBanner () {
      return this.$refs.swiperBanner.swiper
    }
  },
  methods: {
    mouseOver () {
      this.swiperBanner.autoplay.stop()
    },
    mouseLeave () {
      this.swiperBanner.autoplay.start()
    }
  },
  mounted () {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log('this is current swiper instance object', this.swiper)
    // this.swiper.slideTo(1, 1000, false)
  }
}
</script>

<style lang="scss" scoped>
.swiper-banner {
  background: #434343;
  height: 520px;
  // padding-top: 76px;
  position: relative;
  /deep/ .swiper-pagination span {
    display: inline-block;
    width: 80px;
    height: 4px;
    background: #ffffff;
    opacity: 0.4;
    border-radius: 2px;
    margin: 0 16px;
    &.swiper-pagination-bullet-active {
      background: #00dcd4;
    }
  }
  /deep/ .swiper-button-prev {
    left: 30px;
    opacity: 0.6;
  }
  /deep/ .swiper-button-next {
    right: 30px;
    opacity: 0.6;
  }
  /deep/ .swiper-button-prev:hover,
  /deep/ .swiper-button-next:hover {
    opacity: 1;
  }
  .img-bg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .img-txt {
    position: absolute;
    top: 50%;
    left: 30%;
  }
  // .banner1 {
  //   background-image: url("../../assets/images/index-banner11.png");
  // }
  // .banner2 {
  //   background-image: url("../../assets/images/index-banner22.png");
  // }
  // .banner3 {
  //   background-image: url("../../assets/images/index-banner33.png");
  // }
  .dots {
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 28px;
    text-align: center;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 80px 0 55px;
  .en {
    width: 100%;
    height: 69px;
    text-align: center;
    border-bottom: 1px solid #f3f3f3;
    position: absolute;
    top: 80px;
    left: 0;
    background-position: center top;
    background-repeat: no-repeat;
    &.t1 {
      background-image: url("../../assets/images/ABOUT-CDR.png");
    }
    &.t2 {
      background-image: url("../../assets/images/ABOUT-IOT.png");
    }
    &.t3 {
      background-image: url("../../assets/images/Medical-equipment.png");
    }
    &.t4 {
      background-image: url("../../assets/images/Cooperative-partner.png");
    }
  }
  .product {
    position: relative;
    z-index: 1;
    h2 {
      width: 338px;
      height: 68px;
      line-height: 68px;
      color: #6a6a6a;
      font-size: 24px;
      border-bottom: 2px solid #00dcd4;
      text-align: center;
      margin: 0 auto;
      em.color {
        color: #00dcd4;
      }
      em.line {
        color: #e9e9e9;
        padding: 0 10px;
        position: relative;
        top: -2px;
      }
    }
    .sb {
      background: url("../../assets/images/banner33.png") no-repeat center;
      width: 100%;
      height: 450px;
    }
    .details {
      display: flex;
      justify-content: space-between;
      margin: 80px 0;
      .img {
        width: 596px;
        position: relative;
        &.only-img {
          text-align: center;
          img {
            margin: 0 auto;
          }
        }
        .img1 {
          background: #d4f9f6;
          // margin: 26px 0;
          width: 100%;
          height: 95%;
          position: relative;
          top: 2.5%;
        }
        .img2 {
          position: absolute;
          width: 90%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 100%;
          }
          // margin: 0 26px;
        }
      }
      .text {
        width: 575px;
        position: relative;
        &.bg {
          background: #fff url("../../assets/images/logo-bg.png") no-repeat
            center;
        }
        &.border {
          box-shadow: 1px 1px 35px 0px rgba(0, 0, 0, 0.14);
          border-radius: 40px;
          padding: 26px 30px;
        }
        &.border:before {
          content: "";
          position: absolute;
          display: block;
          top: 50%;
          margin-top: -14px;
          right: -31px;
          width: 0px;
          height: 0px;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 31px solid #fff;
        }
        p {
          text-indent: 2em;
          font-size: 16px;
          line-height: 36px;
          color: #6a6a6a;
        }
      }
    }
    .img-list {
      margin: 17px 0 80px;
      overflow: hidden;
      ul {
        width: 1300px;
        overflow: hidden;
      }
      li {
        width: 238px;
        height: 85px;
        border: 1px solid #e5e5e5;
        margin: 76px 82px 0 0;
        float: left;
      }
    }
  }
}

#certify {
  position: relative;
  width: 1200px;
  margin: 60px auto 0;
  padding: 0 50px;
  overflow: hidden;
}

#certify .swiper-container {
  // overflow: initial;
  position: static;
}

#certify .swiper-slide {
  width: 546px;
  height: 341px;
  background: #fff;
  box-shadow: 0 8px 30px #ddd;
  position: relative;
}
#certify .swiper-slide img {
  display: block;
}
#certify .swiper-slide p {
  line-height: 48px;
  text-align: center;
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.5);
  // opacity: .5;
}

#certify .swiper-pagination {
  width: 100%;
  bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  border: 3px solid #fff;
  background-color: #d5d5d5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
  border: 3px solid #00aadc;
  background-color: #fff;
}

#certify .swiper-button-prev {
  left: 0px;
  width: 45px;
  height: 45px;
  background: url("../../assets/images/wm_button_icon.png") no-repeat;
  background-position: 0 0;
  background-size: 100%;
}

#certify .swiper-button-prev:hover {
  background-position: 0 -46px;
  background-size: 100%;
}

#certify .swiper-button-next {
  right: 0px;
  width: 45px;
  height: 45px;
  background: url("../../assets/images/wm_button_icon.png") no-repeat;
  background-position: 0 -93px;
  background-size: 100%;
}

#certify .swiper-button-next:hover {
  background-position: 0 -139px;
  background-size: 100%;
}
</style>
